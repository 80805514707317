import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';
import MainLayout from '../../layouts/main-layout';
import { CheckCircle } from '@material-ui/icons';
import { WebsiteHeading } from '../../components';

const useStyles = makeStyles((theme) => ( {
    pageDescription: {
        maxWidth: '700px',
        overflow: 'hidden',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        marginBottom: '2rem',
    },
    pageBackground: {
        background: '#F6F8FC',
        minHeight: '100%',
    },
    roadmapPage: {
        padding: '40px',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
        position: 'relative',
        marginTop: '64px',
    },
    roadmapContainer: {
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
        '-moz-box-sizing': 'border-box',
        '-webkit-box-sizing': 'border-box',
    },
    roadmapVerticalLinePositioner: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        padding: '20px',
        zIndex: 1,
    },
    roadmapVerticalLine: {
        background: '#dbe4f7',
        width: '2px',
        content: '',
        height: '100%',
    },
    roadmapWrap: {
        position: 'relative',
        zIndex: 10,
        paddingBottom: '30px',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    roadmapYear: {
        borderRadius: '50%',
        color: '#fff',
        background: 'hsl(202deg 58% 19%)',
        height: '60px',
        width: '60px',
        textAlign: 'center',
        lineHeight: '60px',
        fontWeight: 700,
        fontSize: '16px',
        left: '-5px',
        position: 'static',
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 2,
    },
    roadmapItem: {
        display: 'flex',
        position: 'relative',
    },
    itemRight: {
        justifyContent: 'flex-end',

        '& $roadmapBlockLine': {
            right: 0,

            '&::before': {
                marginLeft: '-9px',
                marginTop: '-8px',
                marginRight: 'auto',
            }
        }
    },
    itemLeft: {
        justifyContent: 'flex-start',

        '& $roadmapBlockLine': {
            left: 0,

            '&::before': {
                marginRight: '-9px',
                marginTop: '-8px',
                marginLeft: 'auto',
            }
        }
    },
    roadmapBlock: {
        width: '100%',
        maxWidth: '450px',
        padding: '1.5rem 2rem',
        background: '#fff',
        border: '2px solid #dbe4f7',
        borderRadius: '5px',
        position: 'relative',
        zIndex: 2,

        '& ul': {
            padding: '0px 0px 0px 1rem',
            margin: 0,
            marginTop: '20px',
        },

        '& ul li': {
            padding: 0,
            color: '#485776',
            fontSize: '1rem',
            lineHeight: '1.5',
        },

        [theme.breakpoints.down(1020)]: {
            marginBottom: '30px',
        }
    },
    roadmapBlockLine: {
        height: '2px',
        width: '100%',
        maxWidth: '50%',
        position: 'absolute',
        top: '50%',
        marginTop: '-1px',
        zIndex: 1,
        background: '#dbe4f7',

        '&::before': {
            width: '10px',
            border: '4px solid #f6f8fc',
            height: '10px',
            content: '""',
            display: 'block',
            zIndex: 1,
            background: '#122272',
            borderRadius: '50%',
        }
    },
    roadmapBlockheader: {
        color: '#131F37',
        margin: 0,
        fontFamily: '\'Catamaran\', sans-serif',
        textTransform: 'uppercase',
    },
    roadmapBlockFootnote: {
        marginTop: '2rem'
    },
    statusWrap: {
        position: 'absolute',
        top: '1.5rem',
        right: '1.5rem',
        color: '#0FC1B7',
    },
    pendingLine: {
        borderBottom: '2px dashed #dbe4f7',
        margin: '20px 5px',
    },
    textbox: {
        color: '#485776',
    }
} ));

const content = [
    {
        type: 'year',
        text: '2019'
    },
    {
        type: 'block',
        heading: 'Q4, 2019',
        status: 'complete',
        targets: [
            'Connect with UK fulfilment centers',
            'Identify brands to retail whome share our values',
            'Connect with wholesalers and brands',
            'Launch Tecorra (Launched November 2019)'
        ]
    },
    {
        type: 'year',
        text: '2020'
    },
    {
        type: 'block',
        heading: 'Q1, 2020',
        status: 'complete',
        targets: [
            'Build up to 500 followers across social media channels',
            'Build up product lines',
            'Identify similar brands for co-op in the future',
        ]
    },
    {
        type: 'block',
        heading: 'Q2, 2020',
        status: 'complete',
        targets: [
            'Build up to 1,000 followers across social media channels',
            'Upgrade website',
            'Public circular economy research',
            'Trial various own brand methods',
            'Understand CE limitations, gain access to manufacturing insights',
        ],
    },
    {
        type: 'block',
        heading: 'Q3, 2020',
        status: 'complete',
        targets: [
            'Build up to 1,500 followers across social media channels',
            'Automate as many back end admin tasks as possible',
            'Bring fulfilment in-house *',
            'Analyse onboarding food lines',
            'Obtain circular economy insights through food brand & manufacturers',
            'Analyse UK infrastructure',
            'Conduct financial analysis against initial QNOS plan',
        ],
        footnote: '* Fulfilment was brought in-house earlier than planned (Q1 2021) due to astronimical fee increases caused by COVID-19.',
    },
    {
        type: 'block',
        heading: 'Q4, 2020',
        status: 'complete',
        targets: [
            'Review upcoming packaging innovations',
            'Build up to 2,000 followers across social media channels',
            'Review waste disposal options in the UK',
            'Visit and use German waste facilities',
            'Research other worldwide waste management services',
        ]
    },
    {
        type: 'year',
        text: '2021'
    },
    {
        type: 'block',
        heading: 'Q1, 2021',
        status: 'complete',
        targets: [
            'Analyse typical import processes from Asia and Euope',
            'Analyse the potential hazards around the circular economy (CE)',
            'Detect which third parties potential partners for CE development',
            'Review complexities for small businesses and ensure the solution accepts over 90% of businesses',
            'Review solutions of supply chains reimagined',
        ]
    },
    {
        type: 'block',
        heading: 'Q2, 2021',
        status: 'complete',
        targets: [
            'Connect with local farmers in Tanzania',
            'Soft launch QNOS website',
            'Review certificate authentication',
            'Real world financial viability analysis',
            'Begin Project Phoenix Proof of Concept(s)',
        ]
    },
    {
        type: 'block',
        heading: 'Q3, 2021',
        status: 'pending',
        targets: [
            'Public QNOS website launch',
            'Review 3-5 food supply chains ',
            'Liase with local co-operatives and wholesalers',
            'Connect with at least one exporter in Tanzania to the rest of the world',
            'Completion of Project Phoenix Version 1 Development',
            'Obtain 100+ Brand Sign ups',
            'Obtain 100+ Retailer Sign ups',
        ]
    },
    {
        type: 'pending-line',
    },
    {
        type: 'block',
        heading: 'Q4, 2021',
        status: 'pending',
        targets: [
            'Review problems faced for Tanzanian exporters',
            'QNOS beta version complete',
            'QNOS public press release',
            'QNOS marketing',
            'Obtain QNOS Project Phoenix funding',
            'Begin board of directors hiring',
        ]
    },
    {
        type: 'block',
        heading: 'Q1, 2022',
        status: 'pending',
        targets: [
            'Final network testing',
            'Board of directors hiring complete',
            'Staff onboarding',
            'Premises found & kitted out',
            'Private Test Net',
            'Project Phoenix Launch *',
        ],
        footnote: '*launch expected very late Q1 or Q2'
    },
]

const RoadMapPage = () => {
    const classes = useStyles();
    let renderSide = 0;

    return (
        <MainLayout>
        <main className={classes.pageBackground}>
            <title>QNOS Roadmap</title>
            <div className={classes.roadmapPage}>
                <div className={classes.pageDescription}>
                    <WebsiteHeading text={'Our Road Map'}/>
                    <p className={classes.textbox}>
                        Here you can see our progress as we begin a revolution in the way we consume goods. The dashed 
                        line represents the point at which targets may be updated and changed in the future. Green ticks
                        indicate a completed quarter. Missed targets are moved into subsequent quarters. Cancelled
                        targets are indicated with a line-through.
                    </p>
                </div>
                <div className={classes.roadmapContainer}>
                    <div className={classes.roadmapVerticalLinePositioner}>
                        <div className={classes.roadmapVerticalLine}></div>
                    </div>
                    {
                        content.map((c, index) => {
                            if ( c.type === 'year' ) {
                                return (
                                    <div key={`rm-year-${c.text}`} className={classes.roadmapWrap}>
                                        <div className={classes.roadmapYear}>
                                            { c.text }
                                        </div>
                                    </div>
                                )
                            }
                            else if (c.type === 'block') {
                                renderSide++; 
                                
                                const itemClass = renderSide % 2 === 0 
                                    ? classes.itemLeft 
                                    : classes.itemRight;

                                return (
                                    <div key={`rm-block-${index}`} className={`${classes.roadmapItem} ${itemClass}`}>
                                        <div className={classes.roadmapBlockLine}></div>
                                        <div className={classes.roadmapBlock}>
                                            <div className={classes.statusWrap}>
                                                {
                                                    c.status === 'complete' 
                                                        && (
                                                            <CheckCircle />
                                                        )
                                                }
                                            </div>
                                            <h3 className={classes.roadmapBlockheader}>{c.heading}</h3>
                                            <div>
                                                <ul>
                                                    {
                                                        c.targets.map((t, index) => {
                                                            return (
                                                                <li key={`rm-target-${index}`}>{ t }</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            {
                                                c.footnote && (
                                                    <div className={classes.roadmapBlockFootnote}>
                                                        <small>{ c.footnote }</small>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                );
                            }
                            else if (c.type === 'pending-line') {
                                return (
                                    <div key={`rm-pl-${index}`} className={classes.pendingLine}></div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </main>
        </MainLayout>
    );
}

export default RoadMapPage;